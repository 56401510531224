import React, {
  ReactNode,
  useState,
  useEffect,
  useContext,
} from "react"
import styled, { ThemeProvider } from "styled-components"
import { theme, GlobalStyle } from '../../styles'


import Header from "../Header"
import Lobby from "../Lobby"
import useLocationCondition from "./hooks/useLocationCondition"
import { MyLocationEnum } from "./utils/enum"
import { ESC } from "../../utils/keyCodes"
import ContactForm from "../ContactForm/ContactForm"
import CookieConsent from "../CookieConsent"

import DynamicContext from "../../context"
import useDisableCondition from "./hooks/useDisableCondition"
import CloseButton from "../Header/components/CloseButton"
import { PageProps } from "gatsby"


interface LayoutProps {
  children: ReactNode
  myLocation: MyLocationEnum
  location: PageProps
  onInfo?: () => void
  transitionStatus?: TransitionStatus
  onClose?: () => void
  showInfo?: boolean
  onEye?: () => void
  infoText?: string
}

export default ({
  children,
  myLocation,
  onInfo,
  location,
  onClose,
  showInfo,
  infoText,
  onEye,
}: LayoutProps) => {
  const { liveSpeaker } = useContext(DynamicContext)

  const [showLobby, setShowLobby] = useState(false)
  const [showContactForm, setShowContactForm] = useState(false)

  const handleToggleContact = () => {
    const nextToggle = !showContactForm
    setShowContactForm(nextToggle)
    nextToggle && setShowLobby(false)
  }

  let options = useLocationCondition({ liveSpeaker, myLocation })
  const disableOptions = useDisableCondition({ showContactForm })

  const { alwaysShowLobby, liveButtonActive } = options
  // onClose={() => setShowLobby(false)}

  const handleBack = () => {
    // TODO: This is not optimal
    if (typeof history !== undefined) {
      history.back()
    }
  }

  const handleClose = () => {
    onClose ? onClose() : null
    setShowLobby(false)
    setShowContactForm(false)
  }

  useEffect(() => {
    const shouldListen = showLobby && !alwaysShowLobby

    localStorage.setItem("loggedIn", "anon@advania.is")

    const handleKeydown = (event: KeyboardEvent) => {
      if (event.keyCode === ESC) {
        setShowLobby(false)
      }
    }

    if (shouldListen) {
      document.addEventListener("keydown", handleKeydown, false)
    }

    return () => {
      document.removeEventListener("keydown", handleKeydown, false)
    }
  }, [showLobby, alwaysShowLobby])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      
      <Container>
        <Header
          {...options}
          {...disableOptions}
          onHome={() => setShowLobby(!showLobby)}
          onBack={handleBack}
          homeActive={showLobby || alwaysShowLobby}
          liveActive={liveButtonActive}
          onInfo={onInfo}
          onClose={handleClose}
          contactActive={showContactForm}
          onContact={handleToggleContact}
          onEye={onEye}
        /> 

        <Lobby
          show={showLobby || alwaysShowLobby}
          onCloseLobby={() => setShowLobby(false)}
          myLocation={myLocation}
        />

        <ContactForm
          show={showContactForm}
          onClose={() => setShowContactForm(false)}
          location={location}
        /> 


      <form name="register" data-netlify="true" netlify-honeypot="bot-field" data-netlify-honeypot="bot-field" hidden>
          <input type="hidden" name="form-name" value="register" />
          <input type="text" name="name" />
          <input type="email" name="email" />
          <input type="text" name="company" />
        </form>
        <form name="contact" data-netlify="true" netlify-honeypot="bot-field" data-netlify-honeypot="bot-field" hidden>
          <input type="hidden" name="form-name" value="contact" />
          <input type="text" name="name" />
          <input type="text" name="message" />
          <input type="email" name="email" />
          <input type="text" name="pathname" />
        </form>

        <CookieConsent />

        <RoomOverlay className={showInfo ? "show" : ""}>
          <div className="text">
            {infoText}
            <ContactButtonInOverlay onClick={() => setShowContactForm(true)}>
              Nánari upplýsingar
            </ContactButtonInOverlay>
          </div>
          <CloseOverlayButton onClick={handleClose}></CloseOverlayButton>
        </RoomOverlay>

        <Wrapper>
          <Main>{children}</Main>
        </Wrapper>

      </Container>
    </ThemeProvider>
  )
}

const CloseOverlayButton = styled(CloseButton)`
  position:absolute;
  top:30px;
  right: 165px;
`

const ContactButtonInOverlay = styled.button`
  display: block;
  height: 45px;
  padding: 0 15px;
  border-radius: 22.5px;
  outline: none;
  border: none;
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  margin-top: 10px;
`

const RoomOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.6s ease;

  .text {
    color: #ffffff;
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 36px;
    max-width: 55vw;
    width: 100%;
    padding: 0 30px;
  }

  &.show {
    opacity: 1;
    pointer-events: auto;
    z-index: 200;
  }
`

const Container = styled.div`
  overflow: hidden;
  /* transform: translate(0px, 0px); */

  /*  */
`

const Wrapper = styled.div`
  overflow: hidden;

  &.locked {
    height: 100vh;
  }
`

const Main = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100vw;
  transform: none;
  /* transition: transform .5s cubic-bezier(.59,.01,.28,1); */
`
