import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { number_size } from '../../utils/breakpoints';

interface SeparatorProps{
  width? : string,
  title? : string,
  margin? : string,
  children? : ReactNode,
  handleClick? : (event : any) => void,
  showColor? : boolean,
  lineColor? : string,
  showInMobile?: boolean,
  isButton? : any
}

export const MenuSeparator = ({ width, title, children, margin, handleClick, showColor, lineColor, showInMobile, isButton } : SeparatorProps) => {
  return(
    <Wrapper width={width} margin={margin} showInMobile={showInMobile}>
      <Separator onClick={handleClick} className={isButton ? "isbutton" : ""}>
        <SeparatorTitle>{title}</SeparatorTitle>
        <SeparatorLine showColor={showColor} lineColor={lineColor}></SeparatorLine>
      </Separator>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div<SeparatorProps>`
  width: ${({ width }) => width};
  margin-right: ${({ margin }) => margin};

  ${({showInMobile}) => {
    if(!showInMobile){
      return`
        @media(max-width:550px){
          display:none;
        }
      `
    }else{
      return`
        @media(max-width:550px){
          width:100%;
        }
      `
    }
  }}
`

const Separator = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  margin-bottom:40px;

  &.isbutton{
    cursor:pointer;

    >span{
      font-size:14px;
    }
  }
  
  @media(max-width:${number_size.mobileL}){
    
  }
`

const SeparatorTitle = styled.span`
  font-size:12px;
  color:white;
  margin-right:13px;
  color: #FFFFFF;
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
`
interface SeparatorLineProps{
  showColor? : boolean,
  lineColor? : string
}
const SeparatorLine = styled.div<SeparatorLineProps>`
  width:100%;
  height:2px;
  background-color:white;
  position:relative;

  ${({showColor, lineColor}) =>  {
    if(showColor){
      return`
        &:after{
          content:"";
          position:absolute;
          bottom:-2px;
          height:2px;
          width:100%;
          left:2px;
          background-color:${lineColor};
        }
      `
    }
  }}

`