export enum MyLocationEnum {
  Lobby = "/lobby",
  Itarefni = "/itarefni",
  Skraning = "/",
  Live = "/beint",
  Dagskra = "/dagskra",
  Speaker = "/fyrirlesari",
  _404LostAndAlone = "/404",
  Bransasogur = "/naermynd",
  Framundan = "/framundan",
  Records = "/upptokur",
}
