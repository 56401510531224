import React, { useState, useEffect } from "react"
import { MyLocationEnum } from "../utils/enum"
import { LocationConditionOptions } from "../types"

interface UseLocationConditionProps {
  myLocation: MyLocationEnum
  informationPath?: string
  liveSpeaker: any
}
export default ({
  myLocation,
  informationPath,
  liveSpeaker,
}: UseLocationConditionProps): LocationConditionOptions => {
  let options: LocationConditionOptions = {
    showInfo: false,
    showHome: false,
    showBack: false,
    showGoLive: false,
    showClose: false,
    showSocial: false,
    showContact: false,
    showEye: false,
    alwaysShowLobby: false,
    liveButtonActive: false,
  }

  const isLiveSpeaker = liveSpeaker !== null
  const createLiveSpeakerSlug = (slug: string) => {
    if (slug) {
      return `${MyLocationEnum.Speaker}/${slug}`
    }
    return ""
  }
  const [showMobile, setShowMobile] = useState(false)
  useEffect(() => {
    const resp = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    setShowMobile(resp)
  }, [])
  if (myLocation === MyLocationEnum.Live) {
    return {
      ...options,
      showHome: true,
      showBack: false,
      backTo: MyLocationEnum.Dagskra,
      backText: "Til baka",
      showGoLive: true,
      showSocial: false,
      showContact: false,
      showEye: false,
      showInfo: false,
      // infoTo: isLiveSpeaker && createLiveSpeakerSlug(liveSpeaker.slug),
      liveButtonActive: true,
    }
  } else if (myLocation === MyLocationEnum.Lobby) {
    return {
      ...options,
      showHome: false,
      showGoLive: false,
      showSocial: false,
      showContact: false,
      alwaysShowLobby: true,
    }
  } else if(myLocation === MyLocationEnum.Records) {
    return {
      ...options,
      showHome: true,
      showGoLive: false,
      showSocial: false,
      showContact: false,
    }
  }
  else if (myLocation === MyLocationEnum.Dagskra) {
    return {
      ...options,
      showHome: true,
      showGoLive: false,
      showSocial: false,
      showContact: false,
    }
  } else if (myLocation === MyLocationEnum.Speaker) {
    return {
      ...options,
      showHome: true,
      showGoLive: false,
      showSocial: false,
      showContact: false,
      showBack: false,
    }
  } else if (myLocation === MyLocationEnum.Itarefni) {
    return {
      ...options,
      showHome: true,
      showGoLive: false,
      showSocial: false,
      showContact: false,
      showBack: false,
    }
  } else if (myLocation === MyLocationEnum.Skraning) {
    return {
      ...options,
      showHome: false,
      showGoLive: false,
      showSocial: false,
      showContact: false,
    }
  } else if (myLocation === MyLocationEnum._404LostAndAlone) {
    return {
      ...options,
      showHome: true,
      showGoLive: true,
      showSocial: false,
      showContact: false,
      showBack: true,
    }
  } else if (myLocation === MyLocationEnum.Bransasogur) {
    if (!showMobile) {
      return {
        ...options,
        showHome: true,
        showGoLive: false,
        showSocial: false,
        showContact: false,
        showBack: false,
        showEye: false,
        showClose: true,
        showInfo: false,
      }
    } else {
      return {
        ...options,
        showHome: true,
        showGoLive: false,
        showSocial: false,
        showContact: false,
        showBack: false,
        showEye: false,
        showClose: false,
        showInfo: false,
      }
    }
  }
  return options
}
